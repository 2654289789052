export type LeadSource =
  | 'google'
  | 'kidsinadelaide'
  | 'facebook'
  | 'tiktok'
  | 'instagram'
  | 'x'
  | 'linkedin'
  | 'flyers'
  | 'youtube'
  | 'blog'
  | 'signage'
  | 'referral'
  | 'giftcard'
  | 'newsletter';

export const leadSourceLabels: Record<LeadSource, string> = {
  google: 'Google Search',
  kidsinadelaide: 'Kids In Adelaide Page / Websites',
  facebook: 'Facebook',
  tiktok: 'TikTok',
  instagram: 'Instagram',
  x: 'X (Twitter)',
  linkedin: 'Linkedin',
  flyers: 'Flyers / Poster',
  youtube: 'YouTube',
  signage: 'Signage / Storefront',
  referral: 'Word of Mouth / Referral',
  giftcard: 'Gift Card',
  blog: 'Blog',
  newsletter: 'Newsletter',
};
