export function SuccessIcon() {
  return (
    <svg
      id="fi_6454476"
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m211.302 267.965h145.017l-7.743-28.754 37.963-10.172v-105.467c0-57.87-46.913-104.783-104.783-104.783-57.87 0-104.783 46.913-104.783 104.783v84.931z"
            fill="#ffd15b"
          ></path>
          <path
            d="m211.302 267.965h9.085v-144.393c0-50.427 35.623-92.529 83.076-102.526-7.004-1.476-14.264-2.257-21.708-2.257-57.87 0-104.783 46.913-104.783 104.783v84.931z"
            fill="#ffc344"
          ></path>
          <path
            d="m376.745 433.458c-1.295-5.465-1.688-11.105-1.163-16.697l5.827-55.631-25.358-94.164h-144.172l-127.521-220.874-2.759.438-45.332 26.173-1.683 2.126 125.068 216.624c2.473 4.283 3.775 9.141 3.775 14.087l15.538 110.461c.741 6.087.388 12.257-1.04 18.22l-9.934 58.726c-2.327 9.716 5.037 19.053 15.028 19.053h188.71c9.975 0 17.337-9.311 15.036-19.017z"
            fill="#3b9572"
          ></path>
          <g>
            <path
              d="m202.357 492.947 9.934-58.726c1.428-5.963 1.781-12.134 1.04-18.22l-15.538-110.462c0-4.945-1.302-9.804-3.775-14.087l-125.067-216.623 23.999-13.856-8.592-14.881-49.774 28.737 125.068 216.624c2.473 4.283 3.775 9.141 3.775 14.087l15.538 110.462c.741 6.087.388 12.257-1.04 18.22l-9.934 58.726c-2.327 9.715 5.037 19.053 15.028 19.053h34.367c-9.992-.001-17.356-9.338-15.029-19.054z"
              fill="#97d729"
            ></path>
          </g>
          <g>
            <g>
              <path
                d="m83.138 14.264-12.867 7.429-4.226-7.32c-7.935-13.744-25.511-18.454-39.255-10.518s-18.454 25.511-10.519 39.255l18.313 31.719 62.641-36.166c6.738-3.89 9.046-12.505 5.156-19.243s-12.505-9.046-19.243-5.156z"
                fill="#ffa78f"
              ></path>
            </g>
          </g>
          <g>
            <g>
              <path
                d="m43.96 43.11c-7.935-13.744-3.226-31.32 10.518-39.255.178-.103.358-.194.537-.292-8.516-4.677-19.197-4.921-28.225.292-13.745 7.935-18.454 25.511-10.519 39.255l18.313 31.719 20.767-11.99z"
                fill="#f89580"
              ></path>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="m309.33 261.814v-26.469c-8.542 3.392-17.839 5.277-27.575 5.277-9.735 0-19.033-1.885-27.575-5.277v26.469c0 2.845-2.306 5.151-5.151 5.151h-29.798l21.454 42.052c2.639 5.174 7.957 8.43 13.765 8.43h55.071c5.899 0 11.283-3.358 13.878-8.656l20.485-41.826h-29.403c-2.845 0-5.151-2.306-5.151-5.151z"
                    fill="#ffa78f"
                  ></path>
                </g>
                <g>
                  <g>
                    <path
                      d="m265.535 309.017-21.454-42.052h-24.85l21.454 42.052c2.639 5.174 7.957 8.43 13.765 8.43h24.85c-5.808 0-11.126-3.256-13.765-8.43z"
                      fill="#f89580"
                    ></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="m206.495 165.643v-24.959h-5.258c-15.838 0-28.678 12.839-28.678 28.678 0 15.838 12.839 28.678 28.678 28.678h12.595c-4.7-9.818-7.337-20.805-7.337-32.397z"
                      fill="#f89580"
                    ></path>
                  </g>
                  <g>
                    <path
                      d="m362.273 140.684h-5.258v24.959c0 11.592-2.636 22.579-7.338 32.396h12.596c15.838 0 28.678-12.839 28.678-28.678-.001-15.837-12.84-28.677-28.678-28.677z"
                      fill="#ffb09e"
                    ></path>
                  </g>
                </g>
                <g>
                  <path
                    d="m303.728 105.925-4.148 4.148c-3.631 3.631-8.557 5.671-13.692 5.671h-58.435c-12.042 0-21.804 9.762-21.804 21.804v28.094c0 42.032 34.074 76.106 76.106 76.106 42.032 0 76.106-34.074 76.106-76.106v-43.585c0-12.042-9.762-21.804-21.804-21.804h-18.636c-5.136.001-10.061 2.041-13.693 5.672z"
                    fill="#ffcebf"
                  ></path>
                </g>
                <path
                  d="m233.444 165.643v-28.094c0-12.042 9.762-21.804 21.804-21.804h-27.794c-12.042 0-21.804 9.762-21.804 21.804v28.094c0 42.032 34.074 76.106 76.106 76.106 4.745 0 9.38-.455 13.883-1.292-35.386-6.539-62.195-37.536-62.195-74.814z"
                  fill="#ffb09e"
                ></path>
                <g>
                  <g>
                    <path
                      d="m259.395 155.147c-2.731 0-5.364-1.497-6.688-4.097-1.269-2.491-3.696-3.978-6.491-3.978s-5.222 1.486-6.491 3.978c-1.881 3.689-6.4 5.155-10.087 3.277-3.691-1.881-5.158-6.396-3.278-10.088 3.824-7.505 11.433-12.167 19.856-12.167 8.424 0 16.032 4.662 19.856 12.167 1.88 3.691.413 8.207-3.278 10.088-1.091.557-2.254.82-3.399.82z"
                      fill="#bb5d4c"
                    ></path>
                  </g>
                  <g>
                    <path
                      d="m330.474 155.147c-2.731 0-5.363-1.497-6.688-4.097-1.714-3.363-4.824-3.727-6.082-3.727s-4.368.363-6.082 3.727c-1.88 3.689-6.399 5.155-10.088 3.277-3.69-1.881-5.158-6.397-3.277-10.088 3.746-7.351 11.197-11.916 19.447-11.916s15.701 4.565 19.447 11.916c1.88 3.69.413 8.207-3.277 10.088-1.091.557-2.254.82-3.4.82z"
                      fill="#bb5d4c"
                    ></path>
                  </g>
                </g>
                <g>
                  <path
                    d="m281.75 181.189c-1.641 0-3.292-.535-4.674-1.639l-10.916-8.714c-1.643-1.312-2.66-3.252-2.803-5.35-.144-2.097.6-4.158 2.049-5.682l10.916-11.474c2.855-2.999 7.603-3.117 10.603-.264 3.001 2.854 3.12 7.603.264 10.604l-5.276 5.546 4.522 3.61c3.237 2.584 3.767 7.304 1.182 10.54-1.482 1.858-3.664 2.823-5.867 2.823z"
                    fill="#bb5d4c"
                  ></path>
                </g>
              </g>
              <g>
                <path
                  d="m286.048 218.945h-8.585c-11.853 0-21.462-9.609-21.462-21.462 0-2.371 1.922-4.292 4.292-4.292h42.924c2.371 0 4.292 1.922 4.292 4.292.001 11.853-9.608 21.462-21.461 21.462z"
                  fill="#fd8087"
                ></path>
              </g>
              <path
                d="m272.935 197.482c0-2.371 1.922-4.292 4.292-4.292h-16.934c-2.371 0-4.292 1.922-4.292 4.292 0 11.853 9.609 21.462 21.462 21.462h8.585c1.429 0 2.823-.147 4.174-.413-9.854-1.943-17.287-10.625-17.287-21.049z"
                fill="#fe646f"
              ></path>
            </g>
          </g>
          <g>
            <path
              d="m444.885 379.659-41.564-155.117-3.737-.034-52.234 13.996 42.02 156.03c4.108 15.33 19.865 24.428 35.195 20.32 15.33-4.107 24.428-19.864 20.32-35.195z"
              fill="#3b9572"
            ></path>
            <g>
              <path
                d="m418.463 411.694-48.947-179.129-22.167 5.939 42.02 156.03c3.688 13.762 16.764 22.494 30.492 21.166-.555-1.281-1.026-2.617-1.398-4.006z"
                fill="#97d729"
              ></path>
            </g>
            <g>
              <path
                d="m326.614 215.929 8.458-2.266c3.006-.805 4.79-3.895 3.984-6.901l-.562-2.096c-4.17-15.562 5.052-32.085 20.691-35.959 15.159-3.755 30.598 5.308 34.657 20.459l9.479 35.378-69.867 18.721c-7.6 2.036-15.413-2.547-17.319-10.216-1.87-7.53 2.987-15.113 10.479-17.12z"
                fill="#ffa78f"
              ></path>
            </g>
            <path
              d="m334.258 238.188c-1.87-7.527 2.986-15.111 10.478-17.118l8.458-2.266c3.006-.805 4.79-3.895 3.984-6.901l-.562-2.096c-4.17-15.562 5.052-32.085 20.691-35.959 1.749-.433 3.502-.688 5.241-.791-6.573-4.591-15.008-6.421-23.363-4.351-15.638 3.874-24.861 20.397-20.691 35.959l.562 2.096c.805 3.006-.978 6.096-3.984 6.901l-8.458 2.266c-7.492 2.007-12.348 9.591-10.478 17.118 1.905 7.669 9.719 12.253 17.319 10.216l2.718-.728c-.854-1.296-1.519-2.749-1.915-4.346z"
              fill="#f89580"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path
              d="m498.14 72.414-40.889 7.87c-2.145.413-3.694 2.29-3.694 4.474v66.801c-3.341-1.563-7.067-2.441-11-2.441-14.359 0-26 11.641-26 26s11.641 26 26 26 26-11.641 26-26v-63.934l31.306-6.026c2.145-.413 3.694-2.29 3.694-4.473v-23.798c-.001-2.861-2.608-5.014-5.417-4.473z"
              fill="#fe5ca7"
            ></path>
            <g>
              <path
                d="m462.444 190c-14.359 0-26-11.641-26-26 0-5.518 1.724-10.631 4.656-14.84-13.681.756-24.543 12.087-24.543 25.957 0 14.359 11.641 26 26 26 8.841 0 16.647-4.417 21.344-11.16-.483.027-.968.043-1.457.043z"
                fill="#fe3d97"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <path
            d="m474.449 362.851c-4.142 0-7.5 3.357-7.5 7.5v66.163c-3.751-1.918-7.997-3.008-12.5-3.008-15.188 0-27.5 12.312-27.5 27.5s12.312 27.5 27.5 27.5 27.5-12.312 27.5-27.5v-90.656c0-4.142-3.358-7.499-7.5-7.499z"
            fill="#2ed1e2"
          ></path>
          <g>
            <path
              d="m472.944 477c-15.188 0-27.5-12.312-27.5-27.5 0-5.834 1.822-11.24 4.92-15.69-13.25 1.974-23.415 13.397-23.415 27.196 0 15.188 12.312 27.5 27.5 27.5 9.354 0 17.612-4.674 22.58-11.81-1.333.199-2.697.304-4.085.304z"
              fill="#02c7dd"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path
              d="m405.057 67.089 50.431-25.837c1.866-.956 2.259-3.453.776-4.936l-24.594-24.593c-1.483-1.483-3.98-1.09-4.936.776l-25.837 50.431c-1.368 2.67 1.489 5.527 4.16 4.159z"
              fill="#2ed1e2"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path
              d="m206.481 87.688-25.837-50.431c-.956-1.866-3.453-2.259-4.936-.776l-24.593 24.593c-1.483 1.483-1.09 3.98.776 4.936l50.431 25.837c2.67 1.369 5.528-1.489 4.159-4.159z"
              fill="#2ed1e2"
            ></path>
          </g>
        </g>
        <g>
          <path
            d="m448.352 270.167 37.42-6.331c2.539-.43 3.534-3.547 1.713-5.368l-31.09-31.09c-1.821-1.821-4.939-.826-5.368 1.713l-6.331 37.42c-.363 2.152 1.505 4.02 3.656 3.656z"
            fill="#ffd15b"
          ></path>
        </g>
        <g>
          <path
            d="m70.997 280.679 37.42-6.331c2.539-.43 3.534-3.547 1.713-5.368l-31.09-31.09c-1.821-1.821-4.939-.826-5.368 1.713l-6.331 37.42c-.363 2.152 1.504 4.02 3.656 3.656z"
            fill="#fe9738"
          ></path>
        </g>
        <g>
          <path
            d="m36.299 164.81-18.487 18.487c-1.326 1.326-1.326 3.477 0 4.803l18.487 18.487c1.326 1.326 3.477 1.326 4.803 0l18.487-18.487c1.326-1.326 1.326-3.477 0-4.803l-18.487-18.487c-1.326-1.326-3.477-1.326-4.803 0z"
            fill="#fe5ca7"
          ></path>
        </g>
        <g>
          <g>
            <path
              d="m132.346 319.079-81.425 15.673c-2.018.389-3.477 2.155-3.477 4.21v66.577c-3.615-1.797-7.688-2.813-12-2.813-14.912 0-27 12.088-27 27s12.088 27 27 27 27-12.088 27-27v-66.063l60-11.549v38.648c-3.616-1.797-7.688-2.813-12-2.813-14.912 0-27 12.088-27 27s12.088 27 27 27 27-12.088 27-27v-91.659c0-2.694-2.454-4.72-5.098-4.211z"
              fill="#af66da"
            ></path>
          </g>
          <g>
            <g>
              <g>
                <path
                  d="m28.887 429.725c0-11.292 6.938-20.953 16.778-24.984-3.155-1.292-6.602-2.016-10.222-2.016-14.912 0-27 12.088-27 27s12.088 27 27 27c3.62 0 7.067-.724 10.222-2.017-9.84-4.03-16.778-13.691-16.778-24.983z"
                  fill="#9857bd"
                ></path>
              </g>
            </g>
            <g>
              <g>
                <path
                  d="m103.887 414.948c0-11.292 6.938-20.953 16.778-24.984-3.155-1.292-6.602-2.016-10.222-2.016-14.912 0-27 12.089-27 27 0 14.912 12.088 27 27 27 3.62 0 7.067-.724 10.222-2.017-9.84-4.03-16.778-13.691-16.778-24.983z"
                  fill="#9857bd"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
