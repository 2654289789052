export type AgeGroup =
  | 'earlychildhood'
  | 'primaryschool'
  | 'highschool'
  | 'adult'
  | 'senior';

export const AgeGroupLabels: Record<AgeGroup, string> = {
  earlychildhood: 'Early Childhood',
  primaryschool: 'Primary School',
  highschool: 'High School',
  adult: '18+',
  senior: 'Senior',
};
