'use client';

import { capture } from '@/config/captureUserEvent';
import { EventNames } from '@/config/eventNames';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';

interface ModalProps {
  title: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  onClose: (open: boolean) => void;
}

export default function Modal({ title, children, open, onClose }: ModalProps) {
  function handleClose(value: boolean) {
    capture(EventNames.freeTrialModalClosed, { value });
    onClose(value);
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-[9999999999]">
      <DialogBackdrop
        onClick={() => console.log('backdrop clicked')}
        transition
        className="fixed inset-0 bg-gray-400 bg-opacity-50 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-[100] w-screen sm:overflow-y-hidden">
        <div className="flex min-h-full sm:items-center justify-center text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-scroll sm:overflow-hidden rounded-lg bg-white pb-4 pt-5 h-dvh sm:h-auto text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in md:max-w-[90%] lg:max-w-[80%] xl:max-w-[70%] 2xl:max-w-[65%] sm:my-8 sm:w-full sm:p-0 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 overflow-x-hidden"
          >
            <div>
              <div className="text-center ">
                <DialogTitle
                  as="h3"
                  className="font-semibold leading-6 text-gray-900 text:xl sm:text-3xl border-b border-gray-200  py-2"
                >
                  <div className="inline-flex w-full justify-center px-4 sm:px-16 flex-grow">
                    {title}{' '}
                  </div>
                </DialogTitle>
                <div className="">{children}</div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
