'use client';
import { Instrument } from '@/app/instruments';

const catchAll =
  'https://lottie.host/535b087b-c1d1-4607-bb87-e5e14fd62fb5/Bny1so3G9o.json';

export const lottieUrls: Record<Instrument | 'empty', string> = {
  piano:
    'https://lottie.host/df4d22da-e6c1-4784-9a06-0595ed1969a0/75QXRzSytv.lottie',
  guitar: catchAll,
  electricGuitar: catchAll,
  singing:
    'https://lottie.host/b17f8ccc-2d98-41a9-9a96-beeb817f0e4b/Eo1BY6Ez2W.lottie',
  drums:
    'https://lottie.host/fbf8c5c1-93eb-4478-9a61-66b33c1b4d26/1SWmAfCKal.lottie',
  ukulele:
    'https://lottie.host/a24b1452-cb87-401f-9fe5-f0d9bc9c13f9/lIaC4Cih2y.lottie',
  violin:
    'https://lottie.host/ffd864d6-a084-447e-94c6-71375351f1cf/5QtQwjkeMF.lottie',
  cello:
    'https://lottie.host/df5e5ef7-b8c8-4b5b-9f89-bd8cc022d5a6/tIfu1kSleA.lottie',
  catchAll: catchAll,
  empty:
    'https://lottie.host/7ae4dfa8-7939-4fee-8e68-02d876f6a8cf/nwpPPZ9CYt.lottie',
  saxophone: catchAll,
  clarinet: catchAll,
  flute: catchAll,
  trumpet: catchAll,
  trombone: catchAll,
  songwriting: catchAll,
  bass: catchAll,
  viola:
    'https://lottie.host/ffd864d6-a084-447e-94c6-71375351f1cf/5QtQwjkeMF.lottie',
  frenchHorn: catchAll,
};
