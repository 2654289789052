'use client';
import { useTrialStatus } from '@/app/utils/useTrialStatus';
import React, { useState, useEffect } from 'react';

const InlineCountdown = () => {
  const { isClosed, isClosingSoon, timeLeft } = useTrialStatus();

  if (isClosed) {
    return (
      <>
        {/* <div className="text-sm text-amber-500 font-semibold flex items-center justify-center gap-1 py-1">
          ⏳<span>bookings currently closed • Reopening in {timeLeft}</span>
        </div> */}
      </>
    );
  }

  if (isClosingSoon) {
    return (
      <div className="text-sm text-red-500 font-semibold flex items-center justify-center gap-1 animate-pulse py-1">
        ⏳<span>bookings close in {timeLeft}</span>
      </div>
    );
  }

  return (
    <>
      {/* <div className="text-sm text-emerald-500 font-semibold flex items-center justify-center gap-1 py-1">
        ⏳<span>Bookings are open!</span>
      </div> */}
    </>
  );
};

export default InlineCountdown;
