import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Button } from './Button';
import { AvatarStars } from './AvatarStars';
import dayjs from 'dayjs';
import {
  useEffect,
  useRef,
  useState,
  useTransition,
  useActionState,
} from 'react';

import isToday from 'dayjs/plugin/isToday';
import { Availability, TimeSlot } from '@/app/api/availability/Availability';
import useSWR from 'swr';
import MusicLottie from './MusicLottie';
import { lottieUrls } from './lottieUrls';
import { Instrument, InstrumentLabels } from '@/app/instruments';
import { useFormStatus } from 'react-dom';
import { bookFreeTrialLesson } from './actions/boookFreeTrial.action';
import { z } from 'zod';
import UtcPlugin from 'dayjs/plugin/utc';
import TimeZonePlugin from 'dayjs/plugin/timezone';
import useWindowSize from 'react-use/lib/useWindowSize';
import Weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import Confetti from 'react-confetti';
import NoAvailableTimes from './NoAvailableTimes';
import { teacherEmailToAvatar } from '@/config/team.config';
import { capture, identifyUser } from '@/config/captureUserEvent';
import { EventNames } from '@/config/eventNames';
import {
  instrumentToTeacherEmail,
  TeacherEmails,
  teacherEmailToName,
} from '@/config/TeacherEmails';
import { isEmptyObject } from '@/utils/isEmptyObject';
import { SuccessIcon } from './SuccessIcon';
import Spinner from './Spinner';
import posthog from 'posthog-js';
import { leadSourceLabels } from '@/config/leadSourceLabels';
import { AgeGroupLabels } from '@/config/agegroupLabels';
import { useSearchParams } from 'next/navigation';
import FreeTrialCountdown from './FreeTrialCountdown';

dayjs.extend(UtcPlugin);
dayjs.extend(TimeZonePlugin);
dayjs.extend(isToday);
dayjs.extend(Weekday);
dayjs.extend(isoWeek);

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

interface CalendarDay {
  date: dayjs.Dayjs;
  isCurrentMonth: boolean;
}

const generateCalendarDays = (year: number, month: number): CalendarDay[] => {
  const startOfMonth = dayjs(new Date(year, month, 1));
  const endOfMonth = startOfMonth.endOf('month');

  // Calculate the start day of the month (0 = Sunday, 1 = Monday, etc.)
  const startDay = startOfMonth.day();

  // Calculate the number of days to include from the previous month
  const daysFromPrevMonth = startDay === 0 ? 6 : startDay - 1;

  // Calculate the start date for the grid (including previous month's days)
  const startDate = startOfMonth.subtract(daysFromPrevMonth, 'day');

  const days: CalendarDay[] = [];

  // Generate 42 days for the 7x6 grid
  for (let i = 0; i < 42; i++) {
    const date = startDate.add(i, 'day');
    const isCurrentMonth = date.isSame(startOfMonth, 'month');
    days.push({ date, isCurrentMonth });
  }

  return days;
};

const steps = ['Abour You', 'Select Date', 'Select Time'];

export default function Appointment(props: {
  onClose: () => void;
  autoFocus?: boolean | undefined;
  instrument?: Instrument;
}) {
  const [activeStep, setActiveStep] = useState(1);
  // console.log(generateCalendarDays(dayjs().year(), dayjs().month()));

  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(null);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());
  const [instrument, setInstrument] = useState<Instrument>(
    props.instrument ?? 'piano',
  );
  const [hasSetFirstAvailableTime, setHasSetFirstAvailableTime] =
    useState(false);

  const [name, setName] = useState('');
  const [bookingName, setBookingName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [about, setAbout] = useState('');
  const [leadSource, setLeadSource] = useState('');
  const [location, setLocation] = useState('studio');
  const { width, height } = useWindowSize();

  const searchParams = useSearchParams();
  const [gclid, setGclid] = useState<string>('');

  useEffect(() => {
    // Get gclid from URL
    const gclidParam = searchParams.get('gclid');
    if (gclidParam) {
      setGclid(gclidParam);
      // Optionally store in localStorage/sessionStorage for persistence
      sessionStorage.setItem('gclid', gclidParam);
    }
  }, [searchParams]);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const [selectedTeacher, setSelectedTeacher] = useState<TeacherEmails>(
    instrumentToTeacherEmail[instrument][0],
  );

  const timeSlotsContainerRef = useRef<HTMLDivElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);

  const days = generateCalendarDays(currentYear, selectedMonth);

  const isSelected = (day: { date: dayjs.Dayjs }) =>
    day.date.isSame(selectedDate, 'day');

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FLAG_PREFILL_FORM === 'true') {
      setName('Test Student ' + Math.random().toString(36).substring(2, 15));
      setBookingName(
        'Test Parent ' + Math.random().toString(36).substring(2, 15),
      );
      setEmail(
        `test+${Math.random().toString(36).substring(2, 15)}@musiceternal.life`,
      );
      setPhone('0434138600');
      setAgeGroup('primaryschool');
      setAbout('Have been learning piano for 2 years at school');
      setLeadSource('google');
      setLocation('studio');
    }
  }, []);

  useEffect(() => {
    if (props.autoFocus) {
      nameInputRef.current?.click();
      nameInputRef.current?.focus();
    }
  }, [props.autoFocus]);

  function hasAvailability(availability?: Availability, day?: dayjs.Dayjs) {
    if (!availability || !day) {
      return false;
    }

    const monthAvailability = availability[day.format('YYYY-MM')];

    if (!monthAvailability) {
      return false;
    }

    const dayAvailability = monthAvailability[day.format('DD')];

    if (!dayAvailability) {
      return false;
    }

    return dayAvailability.some((timeSlot) => timeSlot.available);
  }

  const handleNext = () => {
    if (formRef.current?.reportValidity()) {
      capture(EventNames.freeTrialFormCompleted, {
        name,
        bookingName,
        ageGroup,
        about,
        leadSource,
        location,
        instrument,
      });
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    capture(EventNames.freeTrialBackButtonClickedStep2);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function onNextMonth() {
    setSelectedMonth((prev) => prev + 1);
    setSelectedDate(selectedDate.add(1, 'month').startOf('month'));
  }

  function onPreviousMonth() {
    setSelectedMonth((prev) => prev - 1);

    // if today is after start of month then set selected date to today
    if (
      dayjs().isAfter(
        dayjs()
          .month(selectedMonth - 1)
          .startOf('month'),
      )
    ) {
      setSelectedDate(dayjs());
    } else {
      setSelectedDate(selectedDate.subtract(1, 'month').startOf('month'));
    }
  }

  let timeMin: dayjs.Dayjs;
  let timeMax: dayjs.Dayjs;

  timeMin = dayjs().startOf('day');
  timeMax = timeMin.add(2, 'weeks').endOf('day');

  const { data, error, isLoading } = useAvailability({
    timeMin,
    timeMax,
    instrument,
  });

  useEffect(() => {
    if (
      !error &&
      !isLoading &&
      data?.availability &&
      !hasSetFirstAvailableTime
    ) {
      let firstAvailableTime: TimeSlot | undefined;
      let firstAvailableMonth: number | undefined;

      outerLoop: for (const YYYY_MM of Object.keys(data.availability)) {
        innerLoop: for (const DD of Object.keys(
          data.availability[YYYY_MM],
        ).sort((a, b) =>
          dayjs(YYYY_MM + '-' + a).diff(dayjs(YYYY_MM + '-' + b)),
        )) {
          const availableTime = data.availability[YYYY_MM][DD].find((time) => {
            const timeInDayjs = dayjs(time.start);

            timeInDayjs.tz('Australia/Adelaide');

            if (timeInDayjs.isBefore(dayjs())) {
              return false;
            }

            return time.available;
          });

          if (availableTime) {
            firstAvailableTime = availableTime;
            setSelectedMonth(dayjs(YYYY_MM).month());
            setSelectedDate(dayjs(YYYY_MM + '-' + DD));
            setSelectedTime(dayjs(availableTime.start));
            setSelectedTeacher(availableTime.teacher);
            setHasSetFirstAvailableTime(true);
            break outerLoop;
          }
        }
      }
    }
  }, [error, isLoading, data, hasSetFirstAvailableTime, instrument]);

  // useEffect(() => {
  //   if (selectedDate) {
  //     timeSlotsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [selectedDate]);

  const prevSelectedDateRef = useRef<dayjs.Dayjs>(undefined);
  const prevSelectedMonthRef = useRef<number>(undefined);

  useEffect(() => {
    setHasSetFirstAvailableTime(false);
  }, [instrument]);

  useEffect(() => {
    if (activeStep === 2) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (activeStep === 3) {
      step3Ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeStep]);

  useEffect(() => {
    [...new Set(Object.values(lottieUrls))].map((url) => fetch(url));
  }, []);

  const availableTimes = [
    '2:30pm',
    '3:10pm',
    '4:00pm',
    '4:40pm',
    '5:20pm',
    '6:00pm',
  ];

  const bookAFreeTrialAtChosenTime = bookFreeTrialLesson.bind(
    null,
    dayjs(selectedTime).tz('Australia/Adelaide').toDate(),
  );

  const [isPending, startTransition] = useTransition();
  const [state, formAction] = useActionState(bookAFreeTrialAtChosenTime, null);

  function hadleSubmit(payload: FormData): void {
    startTransition(() => {
      formAction(payload);
    });
  }

  useEffect(() => {
    if (state?.done) {
      setActiveStep(3);
      capture(EventNames.freeTrialBookingConfirmationViewed, {
        name,
        email,
        phone,
        bookingName,
        ageGroup,
        about,
        leadSource,
        location,
        instrument,
      });
    }

    if (state?.errors && !isEmptyObject(state.errors)) {
      console.log(state.errors);

      if (state.errors.email) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              mutation.target === firstStepRef.current &&
              getComputedStyle(firstStepRef.current!).display !== 'none'
            ) {
              emailInputRef.current?.focus();
              observer.disconnect();
            }
          });
        });

        observer.observe(firstStepRef.current!, {
          attributes: true,
          attributeFilter: ['style', 'class'],
        });

        setActiveStep(1);
      }
      setActiveStep(1);
    }
  }, [state]);

  const formRef = useRef<HTMLFormElement>(null);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const firstStepRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [position, setPosition] = useState({ top: 0, left: 0, x: 0, y: 0 });

  const { pending } = useFormStatus();

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });

      const { top, left, x, y } = containerRef.current.getBoundingClientRect();
      setPosition({ top, left, x, y });
    }
  }, []);

  return (
    <form
      ref={formRef}
      className="flex flex-col gap-y-4 justify-between w-full relative"
      action={hadleSubmit}
    >
      {isPending && (
        <>
          <div className="absolute bg-green-200 opacity-20 w-full h-full z-10 "></div>
          <div className="absolute  m-auto top-[50dvh] left-[50dvw] sm:top-1/2 sm:left-1/2  -translate-x-16 -translate-y-20 z-20">
            <Spinner />
            <div className="my-3 bg-white rounded-lg">...Loading...</div>
          </div>
        </>
      )}

      <div ref={containerRef} className={classNames(isPending && 'blur-sm')}>
        <div className="flex flex-col justify-center items-center py-2">
          <FreeTrialCountdown />
          <AvatarStars />
        </div>

        {/* STEP 1 */}
        {
          <div
            ref={firstStepRef}
            style={{ display: activeStep === 1 ? 'block' : 'none' }}
            className="text-start text-sm leading-6 px-6 sm:h-[500px] overflow-y-auto"
          >
            <div className="">
              <div className="mt-10 grid grid-cols-1 sm:grid-cols-12 w-full gap-4 gap-x-8">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Student&apos;s Name*
                  </label>
                  <div className="mt-2">
                    <input
                      ref={nameInputRef}
                      required
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 1) {
                          capture(EventNames.freeTrialFieldInputStudentName, {
                            name: e.target.value,
                          });
                        }
                      }}
                      autoFocus={props.autoFocus}
                      autoComplete="full-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      placeholder='e.g. "Taylor Swift" or "Johnny Cash"'
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="bookingname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name of person booking (optional)
                  </label>
                  <div className="mt-2">
                    <input
                      id="bookingname"
                      name="bookingname"
                      type="text"
                      value={bookingName}
                      onChange={(e) => setBookingName(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value.length > 1) {
                          capture(EventNames.freeTrialFieldInputStudentName, {
                            bookingName: e.target.value,
                          });
                        }
                      }}
                      autoComplete="full-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      placeholder='e.g. "Andrea Swift (Mum)" or "Ed Sheeran"'
                    />
                  </div>
                </div>

                <div className="sm:col-span-6 ">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address*
                  </label>
                  <div className="mt-2">
                    <input
                      required
                      ref={emailInputRef}
                      onBlur={(e) => {
                        if (e.target.value.length > 1) {
                          try {
                            z.string().email().parse(e.target.value);
                            console.log('email captured', e.target.value);
                            identifyUser(e.target.value);
                            capture(
                              EventNames.freeTrialFieldInputEmailAddress,
                              {
                                email: e.target.value,
                              },
                            );
                          } catch (error) {
                            console.log(error);
                          }
                        }
                      }}
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        return setEmail(e.target.value);
                      }}
                      autoComplete="email"
                      placeholder="taylorswift@gmail.com"
                      className={classNames(
                        state?.errors?.email &&
                          'focus:ring-red-600 ring-red-600',
                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6',
                      )}
                    />
                    {state?.errors?.email && (
                      <p id="email-error" className="mt-2 text-sm text-red-600">
                        {state?.errors?.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number*
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                      <label htmlFor="country" className="sr-only">
                        Country
                      </label>
                      <select
                        required
                        id="country"
                        name="country"
                        autoComplete="country"
                        className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm"
                      >
                        <option>AU</option>
                        <option>US</option>
                        <option>CA</option>
                      </select>
                    </div>
                    <input
                      required
                      id="phone"
                      name="phone"
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value.length > 1) {
                          z.string().min(8).parse(e.target.value);
                          capture(EventNames.freeTrialFieldInputPhoneNumber, {
                            phone: e.target.value,
                          });
                        }
                      }}
                      placeholder="0482 072 096"
                      className="block w-full rounded-md border-0 py-1.5 pl-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="instrument"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    What Is The Primary Instrument You Wish To Learn?*
                  </label>
                  <div className="mt-2">
                    <select
                      required
                      value={instrument}
                      onChange={(e) =>
                        setInstrument(e.target.value as Instrument)
                      }
                      id="instrument"
                      name="instrument"
                      autoComplete="instrument-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      {Object.entries(InstrumentLabels).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <input
                      type="hidden"
                      name="selectedTeacher"
                      value={selectedTeacher}
                    />
                    <input type="hidden" name="gclid" value={gclid} />
                  </div>
                </div>

                <fieldset className="sm:col-span-6">
                  <legend className="text-sm font-semibold leading-6 text-gray-900">
                    Free Trial Location
                  </legend>
                  <div className="mt-2 space-y-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    {[
                      {
                        id: 'studio',
                        title: 'Studio (687a Brighton Rd Seacliff)',
                        icon: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                            />
                          </svg>
                        ),
                      },
                      {
                        id: 'online',
                        title: 'Online',
                        icon: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                            />
                          </svg>
                        ),
                      },
                    ].map((freeTrialLocation) => (
                      <div
                        key={freeTrialLocation.id}
                        className="flex items-center"
                      >
                        <input
                          checked={freeTrialLocation.id === location}
                          id={freeTrialLocation.id}
                          name="location"
                          value={freeTrialLocation.id}
                          onChange={(e) => {
                            return setLocation(e.target.value);
                          }}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                        />
                        <label
                          htmlFor={freeTrialLocation.id}
                          className="ml-3 inline-flex gap-2 text-sm font-medium items-center leading-6 text-gray-900 cursor-pointer"
                        >
                          {freeTrialLocation.icon}
                          <span>{freeTrialLocation.title}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="agegroup"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Please select the age bracket of the student*
                  </label>
                  <div className="mt-2">
                    <select
                      required
                      id="agegroup"
                      name="agegroup"
                      autoComplete="agegroup"
                      value={ageGroup}
                      onChange={(e) => setAgeGroup(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="" data-isdefault="true">
                        -- Choose Option --
                      </option>
                      {Object.entries(AgeGroupLabels).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="leadsource"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Where Did You Hear About Us?*
                  </label>
                  <div className="mt-2">
                    <select
                      required
                      id="leadsource"
                      name="leadsource"
                      autoComplete="leadsource"
                      value={leadSource}
                      onChange={(e) => setLeadSource(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="" data-isdefault="true">
                        -- Choose Option --
                      </option>
                      {Object.entries(leadSourceLabels).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Has the student learnt music before/are they familiar with
                    music?*
                  </label>
                  <div className="mt-2">
                    <textarea
                      required
                      id="about"
                      name="about"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                      placeholder='e.g. "Yes, they have been learning piano for 2 years at school" or "No, they have never learnt music before. I want to book trial for more than one student"'
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {/* STEP 2 */}
        {
          <div
            style={{ display: activeStep === 2 ? 'block' : 'none' }}
            className="text-center  h-full px-6"
          >
            <h4 className="text-lg pt-4 pb-2">Select Date and Time</h4>
            <div className="grid sm:grid-cols-12 h-full border-gray-100 rounded-lg border-[1px] p-x-4">
              <div className="sm:col-span-4 text-start border-gray-100 sm:border-r-[1px] p-4 flex flex-col gap-1">
                <div className="inline-flex items-center gap-2 text-base w-full">
                  {selectedTeacher && teacherEmailToAvatar[selectedTeacher]}
                  {selectedTeacher && teacherEmailToName[selectedTeacher]}
                </div>
                <h4 className="font-semibold">Free Trial Lesson</h4>
                <h5 className="inline-flex items-center gap-2 text-gray-500 font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  30 minutes
                </h5>
                <p className="text-start my-2">
                  During this free lesson time, we discuss your music interests
                  and your goals as a student muso. Ask us any questions you
                  like, we love questions!{' '}
                </p>
                <p className="text-base font-bold">
                  Everyone ends the trial lesson having learnt one new thing.
                </p>
              </div>
              <div className="sm:col-span-4 p-y-4 p-4">
                <div className="flex items-center justify-center gap-8 text-gray-900 w-full">
                  <button
                    onClick={onPreviousMonth}
                    disabled={selectedMonth === dayjs().month()}
                    role="button"
                    type="button"
                    className="-m-1.5 flex  items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 rounded-lg  disabled:opacity-50  disabled:cursor-not-allowed"
                  >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  <div className=" text-sm font-base">
                    {dayjs().month(selectedMonth).format('MMMM YYYY')}
                  </div>
                  <button
                    role="button"
                    onClick={onNextMonth}
                    type="button"
                    className="-m-1.5 flex  items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 rounded-lg "
                  >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
                <div className="grid grid-cols-7 text-sm font-mono leading-6 text-gray-500">
                  <div>MON</div>
                  <div>TUE</div>
                  <div>WED</div>
                  <div>THU</div>
                  <div>FRI</div>
                  <div>SAT</div>
                  <div>SUN</div>
                </div>
                <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-base  ring-1 ring-gray-200">
                  {days.map((day, dayIdx) => (
                    <button
                      disabled={
                        day.date.isBefore(dayjs(), 'day') || !day.isCurrentMonth
                      }
                      role="button"
                      onClick={handleDateSelection(day)}
                      key={day.date.format('YYYY-MM-DD')}
                      type="button"
                      className={classNames(
                        'py-1.5 hover:bg-gray-100 focus:z-10',
                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                        (day.date?.isSame(selectedDate, 'days') ||
                          day.date.isToday()) &&
                          '',
                        isSelected(day) && 'text-white',
                        !isSelected(day) &&
                          day.isCurrentMonth &&
                          !day.date.isToday() &&
                          'text-green-900',
                        !isSelected(day) &&
                          !day.isCurrentMonth &&
                          !day.date.isToday() &&
                          'text-gray-400',
                        day.date.isToday() &&
                          !isSelected(day) &&
                          'text-green-900',
                        dayIdx === 0 && 'rounded-tl-lg',
                        dayIdx === 6 && 'rounded-tr-lg',
                        dayIdx === days.length - 7 && 'rounded-bl-lg',
                        dayIdx === days.length - 1 && 'rounded-br-lg',
                        'disabled:text-gray-300 disabled:cursor-not-allowed',
                        'relative',
                      )}
                    >
                      <time
                        dateTime={day.date.toISOString()}
                        className={classNames(
                          'mx-auto flex h-8 w-8 items-center justify-center rounded-full',
                          isSelected(day) &&
                            day.date.isToday() &&
                            day.isCurrentMonth &&
                            'bg-green-600',
                          isSelected(day) &&
                            !day.date.isToday() &&
                            'bg-green-600',
                          !isSelected(day) &&
                            day.isCurrentMonth &&
                            hasAvailability(data?.availability, day?.date) &&
                            'bg-green-100 text-green-800',
                        )}
                      >
                        {day.isCurrentMonth
                          ? day.date
                              .format('YYY-MM-DD')
                              .split('-')
                              .pop()
                              ?.replace(/^0/, '')
                          : ''}
                      </time>
                      {day.date.isToday() &&
                        selectedMonth === day.date.month() && (
                          <span className="bg-green-800 absolute left-1/2 top-1/2 flex h-[4px] w-[4px] -translate-x-1/2 translate-y-[8px] items-center justify-center rounded-full align-middle sm:translate-y-[10px]">
                            <span className="sr-only">today</span>
                          </span>
                        )}
                    </button>
                  ))}
                </div>
              </div>
              <div
                ref={timeSlotsContainerRef}
                className="sm:col-span-4 sm:border-l-[1px] py-4 border-gray-100"
              >
                <div className="text-sm">{selectedDate.format('dddd DD')}</div>
                <div className="inline-flex text-sm gap-2 text-center justify-center text-gray-600 pb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>{' '}
                  Australia / Adelaide
                </div>
                <div className="flex flex-col gap-4 overflow-y-auto h-80 px-4 pt-0">
                  {isLoading && (
                    <>
                      {' '}
                      <p className="text-lg">
                        Loading Available Time Slots...
                      </p>{' '}
                      <MusicLottie instrument={instrument} />
                    </>
                  )}
                  {!isLoading &&
                    data.availability?.[selectedDate.format('YYYY-MM')]?.[
                      selectedDate.format('DD')
                    ]
                      ?.filter((a) => a.available)
                      .map((time) => (
                        <Button
                          type="button"
                          role="button"
                          onClick={() => {
                            capture(EventNames.freeTrialTimeSlotSelected, {
                              time: dayjs(time.start).format('HH:mm A'),
                            });
                            setSelectedTeacher(time.teacher);
                            return setSelectedTime(dayjs(time.start));
                          }}
                          key={time.start + time.teacher}
                          variant={
                            selectedTime?.isSame(dayjs(time.start))
                              ? 'solid'
                              : ('outline' as any)
                          }
                          className="w-full relative"
                        >
                          {dayjs(time.start).format('h:mm A')}{' '}
                        </Button>
                      ))}
                  {!isLoading &&
                    data.availability &&
                    !data.availability?.[selectedDate.format('YYYY-MM')]?.[
                      selectedDate.format('DD')
                    ]?.filter((a) => a.available).length && (
                      <NoAvailableTimes />
                    )}
                </div>
              </div>
            </div>
          </div>
        }

        {/* STEP 3 */}
        {activeStep === 3 && (
          <div
            ref={step3Ref}
            className="flex flex-col justify-center items-center py-6 my-auto gap-6 h-[466px]"
          >
            {/* TODO: personalize based on booker/student */}
            <SuccessIcon />
            <h4 className="text-2xl">
              Thanks {bookingName ? bookingName : name} 🎉
            </h4>
            <h5 className="text-lg font-bold px-8">
              Your Booking Is Confirmed for{' '}
              {selectedTime?.format('dddd, MMMM D, YYYY [at] h:mma')}
            </h5>
            <p className="px-16">
              We&apos;re excited to help{' '}
              {bookingName ? (name?.split(' ')?.[0] ?? 'you') : 'you'} learn{' '}
              {instrument}.
              {/* We&apos;ll reach out to you at <strong>{email}</strong> soon. */}
            </p>
            <p className="px-16">Start your musical quest with us.</p>
            <p className="px-16">See you soon! 🎶</p>
            <Confetti
              drawShape={(ctx) => {
                // Draw a bigger music note
                ctx.beginPath();
                // Draw the note head (a filled circle)
                ctx.arc(0, 0, 10, 0, 2 * Math.PI); // Increased radius to 10
                ctx.fill();
                // Draw the note stem (a vertical line)
                ctx.moveTo(10, 0); // Adjusted position
                ctx.lineTo(10, -40); // Increased length to 40
                ctx.stroke();
                // Draw the flag (a curved line)
                ctx.moveTo(10, -40); // Adjusted position
                ctx.quadraticCurveTo(20, -50, 10, -60); // Adjusted curve
                ctx.stroke();
                ctx.closePath();
              }}
              confettiSource={{
                h: dimensions.height,
                w: dimensions.width,
                x: 0,
                y: 0,
              }}
              recycle={false}
              width={dimensions.width}
              height={dimensions.height}
            />
          </div>
        )}
      </div>

      {activeStep === 1 && (
        <div className="flex justify-end border-t-[1px] border-t-gray-200 border-solid py-4  px-16">
          <Button
            className="w-full sm:w-auto"
            role="button"
            color="green"
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      )}
      {activeStep === 2 && (
        <div className="flex justify-end gap-4 border-t-[1px] border-t-gray-200 border-solid py-4 px-4">
          <Button role="button" variant="outline" onClick={handleBack}>
            Back
          </Button>
          <Button
            disabled={!selectedTime}
            role="button"
            color="green"
            type="submit"
          >
            {isLoading ? <>Working ...</> : <>Confirm Booking</>}
          </Button>
        </div>
      )}
      {activeStep === 3 && (
        <div className="flex justify-end gap-4 border-t-[1px] border-t-gray-200 border-solid py-4 px-4 ">
          <Button
            className="w-full sm:w-auto "
            role="button"
            type="reset"
            color="green"
            onClick={(e) => {
              props.onClose?.();
              setTimeout(() => setActiveStep(1), 500);
            }}
          >
            OK
          </Button>
        </div>
      )}
    </form>
  );

  function handleDateSelection(day: CalendarDay) {
    return () => {
      capture(EventNames.freeTrialDateSelected, {
        date: day.date.format('YYYY-MM-DD'),
      });
      setSelectedDate(day.date);
      if (selectedDate) {
        timeSlotsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };
  }
}

function useAvailability(options: {
  timeMin: dayjs.Dayjs;
  timeMax: dayjs.Dayjs;
  instrument: Instrument;
}) {
  const fetcher = async (url: string) => {
    const res = await fetch(url);
    return res.json();
  };

  const searchParams = new URLSearchParams({
    timeMin: options.timeMin.toISOString(),
    timeMax: options.timeMax.toISOString(),
    instrument: options.instrument,
  });

  const { data, error, isLoading } = useSWR(
    `/api/availability?${searchParams.toString()}`,
    fetcher,
  );

  return { data: data as { availability: Availability }, error, isLoading };
}
