import { Instrument } from '@/app/instruments';

export type Teachers =
  | 'Elizabeth'
  | 'Niv'
  | 'Sepideh'
  | 'Todd'
  | 'Sam'
  | 'Annabel'
  | 'Jennifer'
  | 'Nicola'
  | 'Adam'
  | 'Jaymee'
  | 'Ethan'
  | 'Powing'
  | 'Ruby'
  | 'Thomas'
  | 'Directors'
  | 'Bookings'
  | 'Alex'
  | 'Joanne'
  | 'Isabella';
export type TeacherEmails = `${Lowercase<Teachers>}@musiceternal.life`;
export type TeacherEmailToName = {
  [Email in TeacherEmails as Email extends `${infer Name}@musiceternal.life`
    ? Email
    : never]: Capitalize<
    Email extends `${infer Name}@musiceternal.life` ? Name : never
  >;
};
export const teacherEmailToName: TeacherEmailToName = {
  'elizabeth@musiceternal.life': 'Elizabeth',
  'niv@musiceternal.life': 'Niv',
  'powing@musiceternal.life': 'Powing',
  'sepideh@musiceternal.life': 'Sepideh',
  'todd@musiceternal.life': 'Todd',
  'sam@musiceternal.life': 'Sam',
  'annabel@musiceternal.life': 'Annabel',
  'jennifer@musiceternal.life': 'Jennifer',
  'nicola@musiceternal.life': 'Nicola',
  'adam@musiceternal.life': 'Adam',
  'jaymee@musiceternal.life': 'Jaymee',
  'ethan@musiceternal.life': 'Ethan',
  'thomas@musiceternal.life': 'Thomas',
  'ruby@musiceternal.life': 'Ruby',
  'directors@musiceternal.life': 'Directors',
  'alex@musiceternal.life': 'Alex',
  'bookings@musiceternal.life': 'Bookings',
  'joanne@musiceternal.life': 'Joanne',
  'isabella@musiceternal.life': 'Isabella',
};

export const instrumentToTeacherEmail: Record<Instrument, TeacherEmails[]> = {
  piano: ['sepideh@musiceternal.life'],
  guitar: ['ruby@musiceternal.life'],
  electricGuitar: ['ruby@musiceternal.life'],
  singing: ['isabella@musiceternal.life'],
  ukulele: ['powing@musiceternal.life'],
  violin: ['thomas@musiceternal.life'],
  saxophone: ['joanne@musiceternal.life'],
  clarinet: ['joanne@musiceternal.life'],
  flute: ['sepideh@musiceternal.life'],
  trumpet: ['joanne@musiceternal.life'],
  trombone: ['joanne@musiceternal.life'],
  cello: ['powing@musiceternal.life'],
  songwriting: ['powing@musiceternal.life'],
  drums: ['ethan@musiceternal.life', 'adam@musiceternal.life'],
  bass: ['sam@musiceternal.life'],
  catchAll: ['powing@musiceternal.life', 'sepideh@musiceternal.life'],
  viola: ['elizabeth@musiceternal.life'],
  frenchHorn: ['joanne@musiceternal.life'],
};
