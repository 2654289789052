export type Instrument =
  | 'piano'
  | 'guitar'
  | 'electricGuitar'
  | 'singing'
  | 'ukulele'
  | 'violin'
  | 'viola'
  | 'saxophone'
  | 'clarinet'
  | 'flute'
  | 'trumpet'
  | 'trombone'
  | 'frenchHorn'
  | 'cello'
  | 'songwriting'
  | 'drums'
  | 'bass'
  | 'catchAll'; // IF ADDING ANOTHER INSTRUMENT, PLS ADD TO INSTRUMENT MAP IN THE PORTAL INTEGRATION (app/Data/StudentData.php)

export const InstrumentLabels: Record<Instrument, string> = {
  piano: 'Piano',
  guitar: 'Guitar',
  electricGuitar: 'Electric Guitar',
  singing: 'Singing',
  ukulele: 'Ukulele',
  violin: 'Violin',
  viola: 'Viola',
  saxophone: 'Saxophone',
  clarinet: 'Clarinet',
  flute: 'Flute',
  trumpet: 'Trumpet',
  trombone: 'Trombone',
  frenchHorn: 'French Horn',
  cello: 'Cello',
  songwriting: 'Songwriting',
  drums: 'Drums',
  bass: 'Bass Guitar',
  catchAll: 'General Music',
};
