import Image, { StaticImageData } from 'next/image';

export function Avatar(props: { src: string | StaticImageData; alt: string }) {
  return (
    <Image
      className="h-12 w-12 rounded-full  border-white "
      src={props.src}
      alt={props.alt}
      height={64}
      width={64}
    />
  );
}
