'use client';
import { Instrument } from '@/app/instruments';

import dynamic from 'next/dynamic';
import { lottieUrls } from './lottieUrls';

const DotLottieReact = dynamic(
  () => import('@lottiefiles/dotlottie-react').then((m) => m.DotLottieReact),
  { ssr: false },
);

export default function MusicLottie(props: {
  instrument: Instrument | 'empty';
}) {
  return (
    <DotLottieReact
      height={200}
      autoplay
      loop
      src={lottieUrls[props.instrument]}
    />
  );
}
