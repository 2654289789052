import { PlusIcon } from '@heroicons/react/20/solid';
import MusicLottie from './MusicLottie';

export default function NoAvailableTimes() {
  return (
    <div className="text-center">
      <div className="h-40">
        <MusicLottie instrument="empty" />
      </div>
      <h3 className="mt-2 text-base font-semibold text-gray-900">
        No available times found on this day
      </h3>
      <p className="mt-1 text-base text-gray-500">Please try a different day</p>
      <div className="mt-6"></div>
    </div>
  );
}
