'use client';

import { useState, useEffect } from 'react';

interface TrialStatus {
  isClosed: boolean;
  isClosingSoon: boolean;
  timeLeft: string;
  nextClosureDate: Date | null;
  nextOpeningDate: Date | null;
}

interface TrialStatusOverrides {
  forceIsClosed?: boolean;
  forceIsClosingSoon?: boolean;
  forceTimeLeft?: string;
  forceClosureDate?: Date;
  forceOpeningDate?: Date;
  // For testing specific dates
  mockCurrentDate?: Date;
}

export const useTrialStatus = (
  overrides?: TrialStatusOverrides,
): TrialStatus => {
  const [status, setStatus] = useState<TrialStatus>({
    isClosed: false,
    isClosingSoon: false,
    timeLeft: '',
    nextClosureDate: null,
    nextOpeningDate: null,
  });

  useEffect(() => {
    // If any overrides are provided, use them instead of calculating
    if (overrides) {
      if (
        typeof overrides.forceIsClosed !== 'undefined' ||
        typeof overrides.forceIsClosingSoon !== 'undefined'
      ) {
        setStatus({
          isClosed: overrides.forceIsClosed ?? false,
          isClosingSoon: overrides.forceIsClosingSoon ?? false,
          timeLeft: overrides.forceTimeLeft ?? '00m 00s',
          nextClosureDate: overrides.forceClosureDate ?? null,
          nextOpeningDate: overrides.forceOpeningDate ?? null,
        });
        return; // Skip the interval if we're using overrides
      }
    }

    const calculateTimeAndStatus = () => {
      // Use mockCurrentDate for testing or actual current date
      const now = overrides?.mockCurrentDate
        ? new Date(overrides.mockCurrentDate)
        : new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();

      // Rest of your existing calculation logic
      const firstDay = new Date(currentYear, currentMonth, 1);

      let thirdMonday = new Date(firstDay);
      let mondayCount = 0;
      while (mondayCount < 3) {
        if (thirdMonday.getDay() === 1) {
          mondayCount++;
        }
        if (mondayCount < 3) {
          thirdMonday.setDate(thirdMonday.getDate() + 1);
        }
      }

      const closureStart = new Date(thirdMonday);
      closureStart.setHours(0, 0, 0, 0);

      const closureEnd = new Date(thirdMonday);
      closureEnd.setDate(closureEnd.getDate() + 2);
      closureEnd.setHours(23, 59, 59, 999);

      const warningStart = new Date(closureStart);
      warningStart.setDate(warningStart.getDate() - 7);

      const isClosed = now >= closureStart && now <= closureEnd;
      const isClosingSoon = now >= warningStart && now < closureStart;

      let targetDate;
      if (isClosed) {
        targetDate = new Date(closureEnd.getTime());
        targetDate.setHours(9, 0, 0, 0);
      } else if (isClosingSoon) {
        targetDate = closureStart;
      } else {
        if (now > closureEnd) {
          warningStart.setMonth(warningStart.getMonth() + 1);
        }
        targetDate = warningStart;
      }

      const difference = targetDate.getTime() - now.getTime();

      let timeLeft = 'updating...';
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        const daysString = days.toString().padStart(2, '0');
        const hoursString = hours.toString().padStart(2, '0');
        const minutesString = minutes.toString().padStart(2, '0');
        const secondsString = seconds.toString().padStart(2, '0');

        let timeString = '';
        if (days > 0) timeString += `${daysString}d `;
        if (hours > 0) timeString += `${hoursString}h `;
        timeString += `${minutesString}m ${secondsString}s`;

        timeLeft = timeString;
      }

      const reopeningDate = new Date(closureEnd);
      reopeningDate.setDate(reopeningDate.getDate() + 1);
      reopeningDate.setHours(9, 0, 0, 0);

      setStatus({
        isClosed,
        isClosingSoon,
        timeLeft,
        nextClosureDate: closureStart,
        nextOpeningDate: isClosed ? reopeningDate : null,
      });
    };

    const timer = setInterval(calculateTimeAndStatus, 1000);
    calculateTimeAndStatus();

    return () => clearInterval(timer);
  }, [overrides]); // Add overrides to dependencies

  return status;
};
