import { Instrument } from '@/app/instruments';
import { Avatar } from '@/components/Avatar';
import { ReactNode } from 'react';
import Adam from '@/images/team/Adam.jpg';
import Elizabeth from '@/images/team/avatars/Elizabeth Avatar.jpg';
// import Ruby from '@/images/team/Ruby.jpg'
import Sepideh from '@/images/team/Sepideh.jpg';
// import Thomas from '@/images/team/Thomas.jpg';
import Powing from '@/images/team/avatars/PowingAvatar.jpg';
import { TeacherEmails, Teachers } from './TeacherEmails';
import Ethan from '@/images/team/Ethan.jpg';
import Sam from '@/images/team/Sam.jpg';
import Jaymee from '@/images/team/Jaymee.jpg';
import Nicola from '@/images/team/Nicola.jpg';
import Niv from '@/images/team/Niv.jpg';
import Thomas from '@/images/team/avatars/Thomas.jpg';
import Annabel from '@/images/team/Annabel.jpg';
import AlexAvatar from '@/images/team/avatars/AlexAvatar.jpg';
import JoanneAvatar from '@/images/team/avatars/JoanneAvatar.jpg';
import IsabellaAvatar from '@/images/team/avatars/IsabellaAvatar.jpg';
import RubyAvatar from '@/images/team/avatars/RubyAvatar.jpg';
export const teacherEmailToAvatar: Record<TeacherEmails, ReactNode> = {
  'adam@musiceternal.life': <Avatar src={Adam} alt="Adam" />,
  'powing@musiceternal.life': <Avatar src={Powing} alt="Powing" />,
  'elizabeth@musiceternal.life': <Avatar src={Elizabeth} alt="Elizabeth" />,
  'sepideh@musiceternal.life': <Avatar src={Sepideh} alt="Sepideh" />,
  'ethan@musiceternal.life': <Avatar src={Ethan} alt="Ethan" />,
  'niv@musiceternal.life': undefined,
  'todd@musiceternal.life': undefined,
  'sam@musiceternal.life': <Avatar src={Sam} alt="Sam" />,
  'annabel@musiceternal.life': <Avatar src={Annabel} alt="Annabel" />,
  'jennifer@musiceternal.life': undefined,
  'nicola@musiceternal.life': undefined,
  'jaymee@musiceternal.life': undefined,
  'ruby@musiceternal.life': <Avatar src={RubyAvatar} alt="Ruby" />,
  'thomas@musiceternal.life': <Avatar src={Thomas} alt="Thomas" />,
  'directors@musiceternal.life': undefined,
  'alex@musiceternal.life': <Avatar src={AlexAvatar} alt="Alex" />,
  'bookings@musiceternal.life': undefined,
  'joanne@musiceternal.life': <Avatar src={JoanneAvatar} alt="Joanne" />,
  'isabella@musiceternal.life': <Avatar src={IsabellaAvatar} alt="Isabella" />,
};
