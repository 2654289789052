'use client';

import Head from 'next/head';

import Modal from './Modal';
import { useState } from 'react';
import Appointment from './Appointment';
import { Instrument } from '@/app/instruments';
import { capture } from '@/config/captureUserEvent';
import { EventNames } from '@/config/eventNames';

type MusicalPopupProps = {
  children: React.ReactNode;
  instrument?: Instrument;
  autoFocus?: boolean;
};

/**
 * Renders a Calendly popup component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the component.
 * @param {string} props.url - The URL of the Calendly event.
 * @returns {JSX.Element} The rendered CalendlyPopup component.
 */
export function MusiCalPopup({
  children,
  instrument,
  autoFocus = true,
}: MusicalPopupProps) {
  const [open, setOpen] = useState(false);

  const onClickModal = () => {
    capture(EventNames.freeTrialBookCtaClicked, { instrument });
    setOpen(true);
  };

  return (
    <>
      <Modal
        title={<>Free Trial Lesson Booking</>}
        onClose={setOpen}
        open={open}
      >
        <Appointment
          onClose={() => setOpen(false)}
          autoFocus={autoFocus}
          instrument={instrument}
        ></Appointment>
      </Modal>

      <span className="cursor-pointer" onClick={onClickModal}>
        {children}
      </span>
      <Head key="https://calendly.com/assets/external/widget.css">
        <link
          href="https://calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
      </Head>
      {/* <Script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
        strategy="afterInteractive"
      /> */}
    </>
  );
}
